// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {green} from '@mui/material/colors';
import SectionHeader from './SectionHeader';
import {Trans} from 'gatsby-plugin-react-i18next';
import CTAButton from './styled/CTAButton';
import {navigate} from 'gatsby';

const SuccessStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SuccessText = styled.div`
  margin-top: 24px;
  font-size: 1rem;
  text-align: center;
`;

function SuccessFromSubmissionState() {
  return (
    <SuccessStateContainer>
      <CheckCircleIcon sx={{fontSize: 120, color: green['A400']}} />
      <SectionHeader style={{color: 'black', marginTop: 20}}>
        <Trans>Thank you for submitting your request!</Trans>
      </SectionHeader>
      <SuccessText>
        <Trans>
          Our experts will react out to you shortly. If you wish, to skip the wait and create your account for free,
          click the button below!
        </Trans>
      </SuccessText>
      <CTAButton onClick={() => navigate('/business/sign-up')} style={{marginTop: 20}}>
        <Trans>Create account</Trans>
      </CTAButton>
    </SuccessStateContainer>
  );
}

export default SuccessFromSubmissionState;
