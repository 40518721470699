// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Modal} from 'react-bootstrap';
import ContactForm from './ContactForm';

function ContactModal({show, onHide, onSubmit, isLoading, buttonTitle}) {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton />
      <Modal.Body style={{marginTop: 10, marginBottom: 20}}>
        <ContactForm onSubmit={onSubmit} isLoading={isLoading} buttonTitle={buttonTitle} />
      </Modal.Body>
    </Modal>
  );
}

export default ContactModal;
