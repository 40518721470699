/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable relay/graphql-syntax */
// eslint-disable-next-line no-unused-vars
import React, {createRef, useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import {Modal} from 'react-bootstrap';
import Logo from '../components/Logo';
import SectionContainer from '../components/SectionContainer';
import ClientImage from '../assets/images/client.jpeg';
import Proof from '../components/Business/Proof';
import Pricing from '../components/Business/Pricing';
import FAQ from '../components/FAQ';
import API from '../helpers/api';
import Footer from '../components/Footer';
import RedSpan from '../components/RedSpan';
import CTAButton from '../components/styled/CTAButton';
import SuccessFormSubmission from '../components/SuccessFormSubmission';
import ContactForm from '../components/ContactForm';
import ContactModal from '../components/ContactModal';

const Container = styled.div`
  padding-top: 10px;
`;

const TopFold = styled.div``;

const TopFoldSection = styled(SectionContainer)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: calc(100vh - 10px);
  width: 100%;
  background-image: url(${ClientImage});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 805px) {
    margin-top: 30px;

    height: 100vh - 40px; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 40px);

    @media (max-height: 700px) {
      align-items: flex-start;
    }
  }
`;

// const TopFoldBottom = styled.div`
//   position: absolute;
//   bottom: 0px;
//   height: 100px;
//   width: 100%;
//   padding: 0px 50px 0px 50px;
//   background-color: rgba(128, 112, 253, 0.8);
//   /* background-color: black; */
//   display: flex;
//   align-items: center;

//   @media (max-width: 805px) {
//     padding: 30px 20px 30px 20px;
//   }
// `;

// const TopFoldBottomText = styled.div`
//   color: white;

//   @media (max-width: 805px) {
//     font-size: 0.9em;
//   }
// `;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  padding: 0px 50px 0px 50px;
  background-color: #ff3660;
`;

const BottomSectionText = styled.div`
  color: white;
  font-size: 1.3em;

  @media (max-width: 805px) {
    font-size: 0.9em;
  }
`;

// const Logo = styled.img`
//   width: auto;
//   height: 100px;
//   margin: auto;
//   display: block;
// `;

const FormContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 100px;
  margin-left: 100px;
  max-width: 500px;
  /* min-width: 360px; */

  @media (max-width: 805px) {
    margin: 0px 0px 50px 0px;
    padding: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 1;

  /* @media (max-width: 805px) {
    display: none;
  } */
`;

const LogoContaner = styled.div`
  height: 60px;
  display: flex;
  background-color: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-bottom: 2px solid #33333320;
  z-index: 4;
`;

const Heading = styled.div`
  font-size: 1.7em;
  margin-bottom: 10px;
  line-height: 1.2;

  @media (max-width: 805px) {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
`;

const SubHeading = styled.div`
  font-size: 0.95em;
  color: gray;
  line-height: 1.2;
  margin-bottom: 20px;
`;

const FAQ_DATA = [
  {
    title: 'How does the app work?',
    text: 'Your clients download our mobile app and they can start making appointments with you. You can then use our partner app and web based console to accept, edit, cancel or manage their reservations.',
  },
  {
    title: 'Will my client have access to my schedule once they download the app?',
    text: 'No. You will have to invite them by sharing your invite link with them or have them scan your QR code.',
  },
  {
    title: 'What happens if two people book the same time slot?',
    text: 'We make sure this is not possible. We specially put checks in place to only allow booking a time slot that has not been booked by somebody else.',
  },
  {
    title: 'How can get started? How do I create a CindyApp partner account?',
    text: 'Contact us at sales@cindyapp.com.',
  },
  {
    title: 'How can I link my website or Instagram account to CindyApp?',
    text: 'We will generate you a special invite link which you can put on your social media accounts or have a button on your website that leads to it. It is easy as that. Please contact us for more info at sales@cindyapp.com and we will get you started',
  },
  {
    title: 'How many staff members can I have on my CindyApp account? Do I have to pay more?',
    text: 'You can add unlimited number of staff members as well as unlimited number of services. All of our plans also include unlimited reservations by your clients. You only pay a flat fee, no commision or added costs!',
  },
  {
    title: 'What happens if someone calls me and wants to book an appointment? Can I manually add one?',
    text: 'You sure can! You can book the time slots with 3 clicks using your partner mobile app or the web admin interface.',
  },
  {
    title:
      'I have classes with more than one attendees at regular times throughout the week. Can I still use CindyApp?',
    text: 'Yes, of course! We support recurring time slots. You can also set up a maximum number of attendees for each of your services.',
  },
  {
    title: 'Other questions',
    text: 'Please contact us at sales@cindyapp and we would be happy to answer all of your questions.',
  },
];

function LeadPage({params}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isContactFormShown, setIsContactFormShown] = useState(false);
  const [contactFormButtonTitle, setContactFormButtonTitle] = useState('');
  const {t} = useTranslation();

  useEffect(() => {
    // Ref: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const handleSubmit = async (data, clearForm) => {
    setIsLoading(true);

    const body = JSON.stringify(data);
    try {
      await API.post('leads/partner', {method: 'POST', body});

      if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', 'SubmitApplication', data);
      }

      setIsSubmitted(true);
      setIsContactFormShown(false);

      clearForm();
    } catch (e) {
      console.log('error', e);
    }

    setIsLoading(false);
  };

  const showContactForm = (title) => {
    setContactFormButtonTitle(title);
    setIsContactFormShown(true);
  };

  return (
    <Container>
      <LogoContaner>
        <Logo small />
      </LogoContaner>
      <TopFold>
        <TopFoldSection>
          <Column className="hide-on-mobile" />
          <Column>
            <FormContainer>
              <Heading>
                <Trans i18nKey="One solution for <1>all</1> of your existing schedule problems">
                  One solution for <RedSpan>all</RedSpan> of your existing schedule problems
                </Trans>
              </Heading>
              <SubHeading className="hide-on-mobile">
                <Trans>Let CindyApp take care of your scheduling problems</Trans>
              </SubHeading>
              <ContactForm onSubmit={handleSubmit} isLoading={isLoading} buttonTitle={t('START NOW FOR FREE')} />
            </FormContainer>
          </Column>
        </TopFoldSection>
      </TopFold>
      <Proof onButtonClick={() => showContactForm(t('START NOW'))} />
      <Pricing onButtonClick={() => showContactForm(t('START NOW'))} />
      <BottomSection>
        <BottomSectionText>
          <Trans>Време е да въведете ред и автоматизирате графика си.</Trans>
        </BottomSectionText>
        <CTAButton
          style={{marginLeft: 20, color: '#ff3660', backgroundColor: 'white'}}
          onClick={() => showContactForm(t('START NOW'))}
        >
          <Trans>START NOW</Trans>
        </CTAButton>
      </BottomSection>
      <FAQ data={FAQ_DATA} />
      <Footer />
      <Modal show={isSubmitted} centered onHide={() => setIsSubmitted(false)}>
        <Modal.Header closeButton />
        <Modal.Body style={{marginTop: 30, marginBottom: 30}}>
          <SuccessFormSubmission />
        </Modal.Body>
      </Modal>
      <ContactModal
        show={isContactFormShown}
        onHide={() => setIsContactFormShown(false)}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        buttonTitle={contactFormButtonTitle}
      />
    </Container>
  );
}

export default LeadPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
