// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react';
import {Form as _Form} from 'react-bootstrap';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import CTAButton from './styled/CTAButton';
import IndustryDropdown from './IndustryDropdown';
import styled from 'styled-components';
import {Column} from './styled';
import SuccessFormSubmission from './SuccessFormSubmission';

const Container = styled.div`
  box-shadow: 0px 8px 24px 0 rgb(29 140 242 / 16%);
  padding: 24px 16px;
  border-radius: 12px;
`;

const SubmitButton = styled(CTAButton)`
  width: 100%;
  margin-top: 32px;
`;

const Heading = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 32px;
`;

const Form = styled(_Form)`
  font-size: 1.6rem;

  label {
    margin-bottom: 0.2rem;
    margin-left: 4px;
  }
`;

const InputField = styled(Form.Control)`
  min-width: 250px;
`;

function ContactForm({onSubmit, isLoading, buttonTitle, formTitle, containerStyle, successState}) {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [industry, setIndustry] = useState(null);
  const [inquiryText, setInquiryText] = useState('');

  const [validated, setValidated] = useState(false);

  const {t} = useTranslation();

  const clearForm = () => {
    setEmail('');
    setPhoneNumber('');
    setName('');
    setOrganizationName('');
    setIndustry(null);
    setInquiryText('');

    setValidated(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleOrganizationNameChange = (event) => {
    setOrganizationName(event.target.value);
  };

  const handleInquiryTextChange = (event) => {
    setInquiryText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();

      setValidated(true);
    } else {
      onSubmit({email, phoneNumber, name, organizationName, industry, inquiryText}, clearForm);
    }
  };

  return (
    <Container style={{...containerStyle}}>
      {successState ? (
        <SuccessFormSubmission />
      ) : (
        <Form id="lead" noValidate validated={validated} onSubmit={handleSubmit}>
          {formTitle && <Heading>{formTitle}</Heading>}
          <Form.Group>
            <Form.Label className="required">
              <Trans>Your name</Trans>
            </Form.Label>
            <Form.Control
              required
              id="name"
              className="cindy-input"
              type="text"
              // placeholder={t('Enter your business name')}
              onChange={handleNameChange}
              value={name}
            />
            <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
              <Trans>Please provide a name.</Trans>
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group style={{display: 'flex', flexDirection: 'row', gap: 16, flexWrap: 'wrap'}}>
            <Column>
              <Form.Label className="required">
                <Trans>Email</Trans>
              </Form.Label>
              <InputField
                className="cindy-input"
                required
                id="email"
                type="email"
                // placeholder={`${t('Enter your email')}*`}
                onChange={handleEmailChange}
                value={email}
              />
              <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
                <Trans>Please provide a valid email.</Trans>
              </Form.Control.Feedback>
            </Column>
            <Column>
              <Form.Label className="required">
                <Trans>Phone number</Trans>
              </Form.Label>
              <InputField
                required
                className="cindy-input"
                id="phoneNumber"
                type="tel"
                // placeholder={t('Enter your phone number')}
                onChange={handlePhoneNumberChange}
                value={phoneNumber}
              />
              <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
                <Trans>Please provide a phone number.</Trans>
              </Form.Control.Feedback>
            </Column>
          </Form.Group>
          <Form.Group>
            <Form.Label className="required">
              <Trans>Company name</Trans>
            </Form.Label>
            <Form.Control
              required
              id="organizationName"
              className="cindy-input"
              type="text"
              // placeholder={t('Enter your business name')}
              onChange={handleOrganizationNameChange}
              value={organizationName}
            />
            <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
              <Trans>Please provide a company name.</Trans>
            </Form.Control.Feedback>
          </Form.Group>
          <IndustryDropdown style={{marginTop: 16}} onChange={handleIndustryChange} />
          <Form.Group style={{marginTop: 16}}>
            <Form.Label>
              <Trans>How can we help you?</Trans>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              className="cindy-input"
              id="inquiry"
              type="text"
              placeholder={t('Tell us more about your company and how you manage your reservations right now.')}
              onChange={handleInquiryTextChange}
              value={inquiryText}
            />
          </Form.Group>
          <SubmitButton type="submit" $reverse $border disabled={isLoading}>
            {buttonTitle}
          </SubmitButton>
        </Form>
      )}
    </Container>
  );
}

export default ContactForm;
