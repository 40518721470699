// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Form} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';

function IndustryDropdown({onChange, style}) {
  const {t} = useTranslation();

  return (
    <Form.Group style={style}>
      <Form.Label>
        <Trans>Industry</Trans>
      </Form.Label>
      <Form.Control as="select" name="industry" onChange={onChange} className="cindy-input">
        <option value="" disabled selected></option>
        <option value="Sports">{t('Sports')}</option>
        <option value="Beauty">{t('Beauty')}</option>
        <option value="Spa & Therapy">{t('Spa & Therapy')}</option>
        <option value="Health Care">{t('Health Care')}</option>
        <option value="Auto">{t('Auto')}</option>
        <option value="Services">{t('Services')}</option>
        <option value="Education">{t('Education')}</option>
        <option value="Other">{t('Other')}</option>
      </Form.Control>
    </Form.Group>
  );
}

export default IndustryDropdown;
