// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import RedSpan from '../RedSpan';
import SectionContainer from '../SectionContainer';
import ImageUrl from '../../assets/images/man.png';
import SectionHeader from '../SectionHeader';
import SectionSubheader from '../SectionSubheader';
import CTAButton from '../styled/CTAButton';

const Container = styled(SectionContainer)`
  flex-direction: column;
  background-color: white;
  padding-bottom: 0px;
`;

const Text = styled.div`
  text-align: center;
  background-color: #392f5a;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 50px;

  @media (max-width: 805px) {
    padding: 20px;
  }
`;

const Image = styled.img`
  width: 90%;
  object-fit: contain;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 50px;

  @media (max-width: 1092px) {
    padding-top: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// const CTAButton = styled(Button)`
//   margin-left: 0px;
//   margin-bottom: 50px;
//   margin-top: 50px;

//   @media (max-width: 805px) {
//     font-size: 0.8em;
//   }
// `;

function Proof({onButtonClick}) {
  return (
    <Container>
      <div className="hide-on-mobile">
        <SectionHeader inverted>
          <Trans>Proven results</Trans>
        </SectionHeader>
        <SectionSubheader inverted>
          <Trans>Making your clients happier means more revenue for your business</Trans>
        </SectionSubheader>
      </div>
      <ContentContainer>
        <Column className="hide-on-mobile" style={{justifyContent: 'flex-end'}}>
          <Image src={ImageUrl} />
        </Column>
        <Column style={{justifyContent: 'center'}}>
          <Text>
            <span style={{fontStyle: 'italic'}}>
              {/* eslint-disable-next-line max-len */}
              <Trans i18nKey="Our partners experience an average <1>increase of 34%</1> in the total number of appointments just 4 months after they start using CindyApp.">
                Our partners experience an average <RedSpan>increase of 34%</RedSpan> in the total number of
                appointments just 4 months after they start using CindyApp.
              </Trans>
            </span>
            <br />
            <br />
            <Trans>Join now and Make it easy for your clients!</Trans>
          </Text>
          <CTAButton
            style={{marginTop: 30, marginBottom: 50}}
            // to={'/become-partner'}
            onClick={onButtonClick}
            className="become-partner-button"
            id="start-now-proof-button"
            $border
          >
            <Trans>START NOW</Trans>
          </CTAButton>
        </Column>
      </ContentContainer>
    </Container>
  );
}

export default Proof;
